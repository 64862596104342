import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Log } from 'ng2-logger';
import { Modal } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import Spinner from '../../components/templates/spinner';
import SolutionGrid from '../exam-period/solution/solution-calendar';
import { constants } from '../../store/constants/solution-const';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const L = Log.create('CampusIntegrationModal');

function getInitialState(): any {
  const initialValues: any = {
    activeTab: constants.SOLUTION_GET_PUBLIC_TABLES,
    tabManager: [
      { key: constants.SOLUTION_GET_PUBLIC_TABLES, value: true },
      { key: constants.SOLUTION_GET_SINGLE_TABLES, value: false },
      { key: constants.SOLUTION_GET_BY_PROGRAMS, value: false },
      { key: constants.SOLUTION_GET_BY_INSTRUCTORS, value: false },
      { key: constants.SOLUTION_GET_BY_CLASSROOMS, value: false },
      { key: constants.SOLUTION_GET_BY_STUDENTS, value: false },
      { key: constants.SOLUTION_GET_BY_INVIGILATORS, value: false },
      { key: constants.SOLUTION_GET_INVIGILATION_COUNTS, value: false }
    ]
  };
  return Object.assign({}, initialValues);
}

class ExamSolutionModal extends Component<any, any> {
  state = getInitialState();

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.getGeneralSettings();
    this.searchSolution();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.props.dispatch(
      Actions.ApiRequest(Constants.solution.SOLUTION_GET_SINGLE_TABLES, { reset: true }, 'solution-schedule-spinner')
    );
  }

  getGeneralSettings() {
    this.props.dispatch(Actions.ApiRequest(Constants.setting.GET_GENERAL_SETTINGS, 'general-settings-spin'));
  }

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  searchSolution() {
    this.setState({
      ...this.state,
      term_id: this.props.selectedPublicSolution.term_id,
      filters: {
        ...this.state.filters,
        term_id: this.props.selectedPublicSolution.term_id,
      }
    });

    let model: Types.IFilterSolutionModel = {
      term_id: this.props.selectedPublicSolution.term_id,
      active_tab: '0',
      page: 1,
      total: -1,
      size: 10,
      solution_id: 0
    };

    this.props.dispatch(
      Actions.ApiRequest(
        Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES,
        this.props.selectedPublicSolution.term_id,
        'exam-period-modal-tab-spin'
      )
    );

    this.props.dispatch(
      Actions.ApiRequest(Constants.solution.SOLUTION_GET_BY_TERM, model, 'solution-schedule-spinner')
    );
  }

  selectActiveTab = (tabName: string) => {
    let tabs =
      this.state.tabManager &&
      this.state.tabManager.map((tab: any) => {
        return tab.key == tabName ? { key: tab.key, value: true } : { key: tab.key, value: false };
      });

    let activeTab = tabs && tabs.find((item: any) => item.value == true);
    let tabConstant = activeTab && activeTab.key;

    this.setState({
      tab_reloaded: true,
      tabManager: tabs,
      activeTab: tabConstant
    });
  };

  isActiveTab = (tabName: string) => {
    let activeTab = this.state.tabManager && this.state.tabManager.find((item: any) => item.key == tabName);
    if (activeTab) {
      return activeTab.value;
    } else return false;
  };

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.integration.GET_INTEGRATION, { reset: true }, 'integration-spinner')
      );
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    if (this.props.general_settings && this.props.general_settings.restrict_solution_to_instructors) {
      this.state.activeTab = Constants.solution.SOLUTION_GET_BY_STUDENTS;
    }
    return (
      <Modal
        className="pt-0 tw-px-0 md:tw-px-4"
        style={{ maxWidth: '100%', backgroundColor: "#fbfaf6" }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-header">
          <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
            {this.props.selectedPublicSolution.institutionName}
          </h6>
          <div
            style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="dropdown"
              style={{ width: '20px', marginTop: 'auto' }}>
              <button
                className="public-solution-lang-button"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="material-icons" style={{ fontSize: '18px' }}>language</i>
              </button>
              <div className="dropdown-menu " aria-labelledby="dropdownMenuButton">
                <a className="dropdown-item" href="#" onClick={() => T.changeLanguage('tr-TR', true)}>
                  {T.t('gen_lang_tr')}
                </a>
                <a className="dropdown-item" href="#" onClick={() => T.changeLanguage('en-EN', true)}>
                  {T.t('gen_lang_en')}
                </a>
              </div>
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
        <div className="modal-body" >
          <Spinner name="solution-schedule-spinner" />
          <div className="main editor-screen-main">
            <div
              className="white-container mt-4 editor-screen collapse editor-screen"
              style={{ display: 'block', minHeight: '100vh' }}
            >
              <div className="row">
                <Spinner name="exam-period-modal-tab-spin" />
                <div className="col-12">
                  {this.props.general_settings && !this.props.general_settings.restrict_solution_to_instructors ? (
                    <nav className="inner-page-nav">
                      <div className="nav nav-tabs nav-fill mb-4" id="nav-tab" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          onClick={() => this.selectActiveTab(Constants.solution.SOLUTION_GET_SINGLE_TABLES)}
                          id="dates-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          {T.t('gen_all_filters')}
                        </a>
                        <a
                          className="nav-item nav-link"
                          onClick={() => this.selectActiveTab(Constants.solution.SOLUTION_GET_BY_PROGRAMS)}
                          id="classrooms-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          {T.t('gen_program_filters')}
                        </a>
                        <a
                          className="nav-item nav-link"
                          onClick={() => this.selectActiveTab(Constants.solution.SOLUTION_GET_BY_INSTRUCTORS)}
                          id="course-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          {T.t('gen_instructor_filters')}
                        </a>
                        <a
                          className="nav-item nav-link"
                          onClick={() => this.selectActiveTab(Constants.solution.SOLUTION_GET_BY_STUDENTS)}
                          id="students-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          {T.t('gen_student_filters')}
                        </a>
                      </div>
                    </nav>
                  ) : (
                    <nav className="inner-page-nav">
                      <div className="nav nav-tabs nav-fill mb-4" id="nav-tab" role="tablist">
                        <a
                          className="nav-item nav-link"
                          onClick={() => this.selectActiveTab(Constants.solution.SOLUTION_GET_BY_STUDENTS)}
                          id="students-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          {T.t('gen_student_filters')}
                        </a>
                      </div>
                    </nav>
                  )}
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className={
                        this.isActiveTab(Constants.solution.SOLUTION_GET_INVIGILATION_COUNTS)
                          ? 'tab-pane fade'
                          : 'tab-pane fade show active'
                      }
                      id="singleTables"
                      role="tabpanel"
                      aria-labelledby="singleTables-tab"
                    >
                      <SolutionGrid solutionPublishmentInfo={this.props.selectedPublicSolution} termId={this.props.selectedPublicSolution.term_id} activeTab={this.state.activeTab} solutionId={this.props.selectedPublicSolution.solution_id} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    settings: store.state.settings,
    general_settings: store.state.general_settings
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ExamSolutionModal);

export default container;
