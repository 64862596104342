import * as React from 'react';
import ImageMarker, { Marker, MarkerComponentProps } from "react-image-marker";
import { RiArrowGoBackLine } from "react-icons/ri";
import { BsImageFill } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import * as Types from '../../../store/types';
import * as Actions from '../../../store/actions/general';
import * as GT from '../../../tools/general-tools';
import * as Constants from '../../../store/constants/all';
import { routes as Routes } from '../../../store/constants/routes';
import { connect } from 'react-redux';
import Translator from '../../../services/translate-factory';
import { useState, useEffect } from 'react';
import { Badge, Tooltip } from 'antd';
import ImagePopup from '../../../components/image/ImagePopup';
import { AiOutlineInfoCircle, AiOutlineLoading3Quarters } from "react-icons/ai";
import { BuildingFloor, BuildingRoom, MarkerNamed, TableColumn } from '../tools/interfaces';
import Table from '../../../components/table/Table';
import ActionButtons from '../ActionButtons';
import { Log } from 'ng2-logger';
import { fallbackImage1 } from '../../event-period/constants';
import cn, { flexCenter } from '../../../components/ui/Tailwind';
import Warning from '../../../components/warning';
import { debounce } from 'lodash';
import AppFactory from '../../../services/app-factory';
import { UserRole } from '../../../store/constants/enums';
const AF = AppFactory.create()

const T = Translator.create();
const L = Log.create('SketchesDetail');

interface SketchesDetailProps {
    returnText: (T: Translator) => string;
    type: string;
    form?: Types.ICampusItem | BuildingFloor;
    dispatch?: any,
    user?: Types.IAuthUser,
    publicSketches?: boolean
}

const SketchesDetail: React.FC<SketchesDetailProps> = ({ publicSketches, returnText, type, dispatch, form: propForm, user }) => {
    const [form, setForm] = useState<Types.ICampusItem | BuildingFloor | undefined>(propForm)

    const [selectedBuilding, setSelectedBuilding] = useState<Types.IBuildingItem>();
    const [selectedRoom, setSelectedRoom] = useState<BuildingRoom>();
    const [showMarkers, setShowMarkers] = useState<MarkerNamed[]>([]);
    const [openImageModal, setOpenImageModal] = useState(false);
    const [showImageArray, setShowImageArray] = useState<string[]>([]);
    const [editObj, setEditObj] = useState<BuildingRoom | Types.IBuildingItem>();
    const [tempMarker, setTempMarker] = useState<MarkerNamed>();

    const [, forceRender] = React.useReducer(x => x + 1, 0);
    const handleLanguageChange = React.useCallback(
        debounce(() => {
            forceRender(1)
        }, 1000),
        []
    );
    const [loading, setLoading] = useState(true);
    const [imageLoading, setImageLoading] = useState(true);

    useEffect(() => {
        setForm(propForm);
    }, [propForm]);

    useEffect(() => {
        if (form) {
            setLoading(false);
            window.scrollTo(0, 0);
        }
    }, [form]);

    useEffect(() => {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        return () => {
            T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);

        };
    }, []);

    useEffect(() => {
        fillMarker();
    }, [selectedBuilding, form, selectedRoom]);

    useEffect(() => {
        if (editObj) {
            setShowMarkers(getSingleMarkerFromObj(editObj));
        } else {
            fillMarker();
        }
    }, [editObj]);

    useEffect(() => {
        if (tempMarker) {
            setShowMarkers([tempMarker]);
        }
    }, [tempMarker]);

    function fillMarker() {
        if (form) {
            if ("campus_code" in form) {
                if ((!selectedBuilding) && form.connectedBuildings) {
                    setShowMarkers([]);
                    // setShowMarkers(getAllMarkers(form.connectedBuildings));
                } else if (selectedBuilding) {
                    setShowMarkers(getSingleMarkerFromObj(selectedBuilding));
                }
            } else if (form && form.rooms) {
                if (!selectedRoom) {
                    setShowMarkers([]);
                } else {
                    setShowMarkers(getSingleMarkerFromObj(selectedRoom));
                }
            }
        }
    }

    const locationMarker = (prop: MarkerComponentProps) => {
        const getMarkerNamed = showMarkers.at(parseInt(prop.itemNumber.toString()));
        const iconColor = editObj ? "#e8951a" : '#eb5426';

        return (

            <div className='tw-relative tw-rounded-full' style={{ backgroundColor: iconColor }}>
                <MdLocationOn className={cn(
                    "tw-absolute -tw-top-9 -tw-left-[1.20rem]",
                    !editObj && (selectedBuilding || selectedRoom) ? "tw-animate-ping tw-absolute" : "tw-hidden"
                )} style={{ fontSize: '2.625rem', color: iconColor }} />
                <Tooltip placement="top" title={getMarkerNamed && getMarkerNamed.name}>
                    <MdLocationOn className={cn(
                        "tw-absolute -tw-top-9 -tw-left-[1.20rem]",
                        { "tw-animate-bounce ": editObj },
                    )} style={{ fontSize: '2.625rem', color: iconColor }} />
                </Tooltip>
            </div>

        );
    };

    const updateFormState = (updatedItem: Types.IBuildingItem | BuildingRoom) => {
        if ('building_id' in updatedItem && form && "connectedBuildings" in form) {
            const updatedBuildings = form.connectedBuildings && form.connectedBuildings.map(building =>
                building.building_id === updatedItem.building_id ? updatedItem : building
            );
            setForm({ ...form, connectedBuildings: updatedBuildings });
        } else if ("id" in updatedItem && form && "rooms" in form) {
            const updatedRooms = form.rooms.map(room =>
                room.id === updatedItem.id ? updatedItem : room
            );
            setForm({ ...form, rooms: updatedRooms });
        }
    };

    function removeMarkerFromObj(obj: Types.IBuildingItem | BuildingRoom) {
        if ('building_id' in obj && form && "connectedBuildings" in form) { // campus sketch -> building marker
            delete obj.marker

            // obj -> Types.IBuildingItem
            dispatch(
                Actions.ApiRequest(Constants.sketch.SKETCH_BUILDING_MARKER_UPDATE, obj, 'sketch-campus-form-spin', () => { })
            );

        } else if ("id" in obj) { // floor sketch -> room marker
            delete obj.marker

            // obj -> BuildingRoom
            dispatch(
                Actions.ApiRequest(Constants.sketch.SKETCH_CLASSROOM_MARKER_UPDATE, obj, 'sketch-building-form-spin', () => { })
            );

        }
        updateFormState(obj)
    }

    function handleDelete(obj: Types.IBuildingItem | BuildingRoom) {
        dispatch(
            Actions.ShowModal({
                title: T.t('gen_delete_action'),
                body: T.t('gen_delete_location', { name: obj.name }),
                name: 'Location',
                icon: 'delete',
                iconColor: 'red',
                confirm: T.t('gen_yes'),
                cancel: T.t('gen_cancel'),
                onConfirm: () => removeMarkerFromObj(obj)
            })
        )
    }

    function handleEditSave() {
        // Types.ICampusItem | BuildingFloor

        if (!editObj || !tempMarker)
            return

        const updatedObj = {
            ...editObj,
            marker: {
                top: tempMarker.top,
                left: tempMarker.left
            }
        }

        if ('building_id' in editObj) { // campus sketch -> building marker

            // editObj -> Types.IBuildingItem
            dispatch(
                Actions.ApiRequest(Constants.sketch.SKETCH_BUILDING_MARKER_UPDATE, updatedObj, 'sketch-campus-form-spin', () => { })
            );

        } else if ("id" in editObj) { // floor sketch -> room marker

            // editObj -> BuildingRoom
            dispatch( // Yukarıda benzer burada da BuildingRoom objesine gore guncelleme yapılmalı
                Actions.ApiRequest(Constants.sketch.SKETCH_CLASSROOM_MARKER_UPDATE, updatedObj, 'sketch-building-form-spin', () => { })
            );

        }

        updateFormState(updatedObj)
        setEditObj(undefined)
    }

    const backButton = () => {
        if (publicSketches) {
            return dispatch(Actions.Navigation(GT.Route(Routes.LOGIN + Routes.SKETCHES)), undefined, undefined, () => setForm(undefined));
        }
        dispatch(Actions.Navigation(GT.Route(Routes.SKETCHES)), undefined, undefined, () => setForm(undefined));
    };

    function getAllMarkers(list: Types.IBuildingItem[] | BuildingRoom[]) {
        return list.map(item => {
            if (item.marker) {
                return {
                    ...item.marker,
                    name: item.name
                };
            }
        }).filter(marker => marker !== undefined) as MarkerNamed[];
    }

    function getSingleMarkerFromObj(item: Types.IBuildingItem | BuildingRoom) {
        if (item.marker) {
            return [
                {
                    left: item.marker.left,
                    top: item.marker.top,
                    name: item.name
                }
            ];
        }
        return [];
    }

    const getImageSrc = () => getSketchSrc() || fallbackImage1;


    const getSketchSrc = () => {
        if (form && "sketches" in form && form.sketches) {
            return form.sketches[0].src;
        }
        return null
    }

    const handleImageMarker = (newPoint: Marker) => {

        if (editObj) {
            const tempMarkerNamed = {
                top: newPoint.top,
                left: newPoint.left,
                name: editObj.name,

            };
            setTempMarker(tempMarkerNamed);
        }
    }

    const customBuildingActionRender = (item: any) => {
        return <td className='text-center'>
            <ActionButtons
                isEmptyPhoto={!getSketchSrc()}
                editObj={editObj}
                form={form}
                handleDelete={handleDelete}
                handleEditSave={handleEditSave}
                setEditObj={setEditObj}
                setShowMarkers={setShowMarkers}
                row={item} selectedRow={selectedRoom} setStateSelected={setSelectedRoom}
            />
        </td>
    }

    const customBuildingImageRender = (item: BuildingRoom) => {
        const images = item.photos != undefined ? item.photos : [];
        const mergedImageList: string[] = [];
        images && images.forEach(j => {
            mergedImageList.push(j.src);
        });

        return <td data-label={T.t('gen_name')} className="text-center">
            <Badge count={mergedImageList.length} size='small'>
                <button
                    disabled={images && images.length < 1}
                    style={{ border: 'none', backgroundColor: 'transparent' }}
                    className="disabled:tw-opacity-50"
                    onClick={() => {
                        setOpenImageModal(true);
                        setShowImageArray(mergedImageList);
                    }}
                >
                    <BsImageFill style={{ fontSize: '24px' }} />
                </button>
            </Badge>
        </td>;
    }

    const buildingColumn: TableColumn[] = [
        {
            __componentType: "oneLine",
            __headerType: "default",
            parseName: "",
            customRender: customBuildingActionRender,
            title: T.t("gen_actions")
        },
        {
            __componentType: 'oneLine',
            __headerType: 'default',
            title: T.t('gen_classrooms'),
            parseName: "name",
        },
        {
            __componentType: 'oneLine',
            __headerType: 'default',
            title: T.t('gen_order'),
            parseName: "orderLine",
        },
        {
            __componentType: 'oneLine',
            __headerType: 'default',
            customRender: customBuildingImageRender,
            title: T.t("gen_images"),
            parseName: "",
        },
    ]
    const customCampusActionRender = (item: any) => {
        return <td className='text-center'>
            <ActionButtons
                isEmptyPhoto={!getSketchSrc()}
                editObj={editObj}
                form={form}
                handleDelete={handleDelete}
                handleEditSave={handleEditSave}
                setEditObj={setEditObj}
                setShowMarkers={setShowMarkers}
                row={item} selectedRow={selectedBuilding} setStateSelected={setSelectedBuilding}
            />
        </td>
    }
    const customCampusImageRender = (item: Types.IBuildingItem) => {
        const images = item.photos != undefined ? item.photos : [];
        const mergedImageList: string[] = [];
        images && images.forEach(j => {
            mergedImageList.push(j.src);
        });

        return <td data-label={T.t('gen_name')} className="text-center">
            <Badge count={mergedImageList.length} size='small'>
                <button
                    disabled={images && images.length < 1}
                    title={mergedImageList && mergedImageList.length < 1 ? T.t('gen_no_image') : T.t("gen_show_images")}
                    style={{ border: 'none', backgroundColor: 'transparent' }}
                    className="disabled:tw-opacity-50"
                    onClick={() => {
                        setOpenImageModal(true);
                        setShowImageArray(mergedImageList);
                    }}
                >
                    <BsImageFill style={{ fontSize: '24px' }} />
                </button>
            </Badge>
        </td>;
    }

    const campusColumn: TableColumn[] =
        [
            {
                __componentType: "oneLine",
                __headerType: "default",
                parseName: "",
                customRender: customCampusActionRender,
                title: T.t("gen_actions")
            },
            {
                __componentType: 'oneLine',
                __headerType: 'default',
                title: T.t("gen_connected_buildings"),
                parseName: "name",
            },
            {
                __componentType: 'oneLine',
                __headerType: 'default',
                customRender: customCampusImageRender,
                title: T.t("gen_images"),
                parseName: "",
            },
        ]

    const isAdmin = () => {
        return AF.inRole([UserRole.SYSTEM])
    }

    const getResultsForTable = () => {
        if (!form)
            return []

        if ("connectedBuildings" in form) {
            if (isAdmin())
                return form.connectedBuildings
            return getUnAuthorizedResultTable(form.connectedBuildings)
        } else if ("rooms" in form) {
            if (isAdmin())
                return form.rooms
            return getUnAuthorizedResultTable(form.rooms)
        }
        return []
    }

    const getUnAuthorizedResultTable = (elements?: Array<Types.IBuildingItem | BuildingRoom>) => {
        return elements && elements.filter((element) => element.marker)
    }

    const getColumnForTable = () => {
        if (type === 'campus') {
            return campusColumn
        } else if (type === 'building') {
            return buildingColumn
        }
        return []
    }

    if (loading) {
        return <div className={cn(flexCenter, 'tw-h-screen')}><AiOutlineLoading3Quarters className='tw-animate-spin tw-text-2xl' /></div>
    }

    return (
        <div className='tw-relative'>
            <div className="d-flex flex-column">
                <div className="d-flex flex-column justify-content-start">
                    <div className="flex-row d-flex justify-content-between align-items-center tw-text-sm md:tw-text-base">
                        <span>
                            {
                                form &&
                                (
                                    "rooms" in form
                                        ? (form.building_name ? `${form.building_name} - ` : "") + `${T.t("gen_floor")} ${form.name || form.floor} `
                                        : form.name
                                )
                            }
                        </span>
                        <div className='tw-flex tw-items-center tw-gap-2'>
                            <button
                                onClick={() => backButton()}
                                className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-bg-gray-100 tw-rounded-lg hover:tw-bg-gray-200 tw-p-2 tw-gap-2 tw-duration-200 tw-border tw-border-gray-200"
                            >
                                <span>
                                    {returnText(T)}
                                </span>
                                <RiArrowGoBackLine />
                            </button>
                        </div>
                    </div>
                    <div className="mt-4 white-container">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-2">
                            <div className={editObj ? "tw-border-2 tw-border-dashed tw-p-4 tw-rounded-lg tw-relative" : ""}>
                                <div className={"tw-flex tw-flex-row tw-items-center tw-gap-2 " + (editObj ? "tw-block" : "tw-hidden")}>
                                    <span>
                                        {T.t("gen_edit")}
                                    </span>
                                    <Tooltip title="Yeni konumun üzerine tıklayın." placement='right'>
                                        <AiOutlineInfoCircle />
                                    </Tooltip>
                                </div>
                                {!getSketchSrc()
                                    && <Warning show classNames='tw-mb-2'>
                                        {T.t("gen_no_sketch_warning")}
                                    </Warning>
                                }
                                <img
                                    src={getImageSrc()}
                                    style={{ display: "none" }}
                                    onLoad={() => {
                                        setImageLoading(false)
                                    }}
                                    alt="hidden for loading check"
                                />
                                {!imageLoading ? <ImageMarker
                                    extraClass='tw-rounded'
                                    src={getImageSrc()}
                                    markers={showMarkers}
                                    markerComponent={locationMarker}
                                    onAddMarker={handleImageMarker}
                                    bufferLeft={1}
                                    bufferTop={2}
                                /> : <div className='tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center'><AiOutlineLoading3Quarters className='tw-animate-spin tw-text-lg' /></div>}

                            </div>
                            <div className="">
                                <Table
                                    key={propForm && propForm.name}
                                    notFoundMessage={type === 'campus' ? T.t('gen_no_building_found') : T.t('gen_no_classroom_found')}
                                    disableFetch={true}
                                    disableFetchPagination={true}
                                    results={getResultsForTable()}
                                    rowClassname="tw-group"
                                    tableClassname='tw-shadow-none'
                                    columnComponent={getColumnForTable()}
                                    mobileView={(item) => {

                                        return <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
                                            <div className="tw-flex tw-justify-start tw-flex-grow tw-items-center">
                                                <div className="tw-flex tw-flex-grow tw-flex-col tw-justify-start tw-w-12 tw-ml-4">
                                                    <p className="tw-text-sm tw-text-start tw-font-bold tw-mb-1 tw-truncate">{item.name}</p>
                                                </div>
                                            </div>
                                            <div className='tw-flex-row tw-flex'>
                                                {
                                                    type === 'campus'
                                                        ? <>
                                                            {customCampusActionRender(item)}
                                                            {customCampusImageRender(item)}
                                                        </>
                                                        : <>
                                                            {customBuildingActionRender(item)}
                                                            {customBuildingImageRender(item)}
                                                        </>
                                                }

                                            </div>
                                        </div>

                                    }}
                                />
                                <ImagePopup open={openImageModal} setClose={setOpenImageModal} imageArray={showImageArray} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

const mapStateToProps = (store: Types.IPersistedState, ownProps: SketchesDetailProps): SketchesDetailProps => {
    if (!store || !store.state) {
        return ownProps;
    }

    let form;
    if (ownProps.type === 'campus') {
        form = store.state.sketch_page && store.state.sketch_page.campus_form || undefined;
    } else if (ownProps.type === 'building') {
        form = store.state.sketch_page && store.state.sketch_page.building_form && store.state.sketch_page && store.state.sketch_page.building_form || undefined;
    }

    return {
        ...ownProps,
        form: form,
        user: store.state.user
    };
};

const equal = require('deep-equal');

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
    return equal(prev, next);
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(SketchesDetail);

export default container;
